import { inject, isDevMode, Pipe, PipeTransform } from '@angular/core';
import { NgControl } from '@angular/forms';

import { DEFAULT_ERROR_MESSAGE_FN, DEFAULT_ERROR_MESSAGES, IErrorMessageFnOptions } from '@core/constants/app.constants';

@Pipe({
  name: 'formControlError',
  standalone: true,
})
export class FormControlErrorPipe implements PipeTransform {
  ngControl = inject(NgControl, {
    skipSelf: true,
  });

  public transform(errors: {[key: string]: any }, options: IErrorMessageFnOptions): string {
    if (!errors) {
      return null;
    }

    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const errorObj = this.ngControl.control.getError(errorKeys[0]);
      const errorMessage = errorObj.message;
      const errMessageFnByKey = DEFAULT_ERROR_MESSAGES[errorKeys[0]];
      const errorMessageFn = errMessageFnByKey || DEFAULT_ERROR_MESSAGE_FN;

      if (!errorMessage && !errMessageFnByKey && isDevMode()) {
        console.warn(`No error message found for error keys: ${Object.keys(this.ngControl.control.errors)}`);
      }

      return errorMessage || errorMessageFn(errorObj, options) || null;
    } else {
      return null;
    }
  }
}
