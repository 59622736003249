<div *ngIf="readonly === true; else regularInput">
  <app-label-value [label]="label">
    <p>
      <ng-container *ngIf="dataType === 'price'">
        {{formControl.value ? '$' + formControl.value : formControl.value | appEmptyValue}}
      </ng-container>
      <ng-container *ngIf="dataType !== 'price'">
        {{formControl.value | appEmptyValue}}
      </ng-container>
    </p>
  </app-label-value>
</div>
<ng-template #regularInput>
  <mat-form-field class="form-input _common-mat-form-field"
                  [floatLabel]="floatLabel"
                  [class.form-input--disabled]="readonly === 'input'"
                  [class._common-theme-field]="!!formControl.value"
                  [class.form-input_big]="big">

    <mat-label *ngIf="label">{{label}}</mat-label>
    <ng-container matPrefix>
      <ng-content select="[prefix]"></ng-content>
      <mat-icon class="form-input__prefix-icon" *ngIf="dataType === 'phone'">call</mat-icon>
      <mat-icon class="form-input__prefix-icon" *ngIf="dataType === 'price'">attach_money</mat-icon>
    </ng-container>
    <ng-container matSuffix>
      <mat-icon *ngIf="tooltipText || tooltipAttentionText"
                [matTooltip]="tooltipText || tooltipAttentionText"
                svgIcon="help_outline"></mat-icon>
      <mat-icon class="control-error-icon">error</mat-icon>
      <ng-content select="[suffix]"></ng-content>
    </ng-container>

    <div class="form-input__wrapper-data-type">
      <ng-container [ngSwitch]="dataType">
        <input *ngSwitchCase="'price'"
               matInput
               trim="blur"
               appCurrency
               [autocomplete]="autocomplete"
               [type]="type"
               (keydown.enter)="enterClicked()"
               [minlength]="minLength"
               [maxlength]="maxLength"
               [placeholder]="placeholder"
               [formControl]="formControl"
               [readonly]="readonly"
               [required]="required"
               (focus)="inputFocus.emit()"
               (blur)="inputBlurHandler()"
               (input)="fieldInput.emit()">
        <input *ngSwitchCase="'only-number'"
               matInput
               trim="blur"
               [appOnlyNumber]="true"
               [autocomplete]="autocomplete"
               [type]="type"
               (keydown.enter)="enterClicked()"
               [minlength]="minLength"
               [maxlength]="maxLength"
               [placeholder]="placeholder"
               [formControl]="formControl"
               [readonly]="readonly"
               [required]="required"
               (focus)="inputFocus.emit()"
               (blur)="inputBlurHandler()"
               (input)="fieldInput.emit()">
        <input *ngSwitchDefault
               matInput
               trim="blur"
               [autocomplete]="autocomplete"
               [type]="type"
               (keydown.enter)="enterClicked()"
               [minlength]="minLength"
               [maxlength]="maxLength"
               [placeholder]="placeholder"
               [formControl]="formControl"
               [readonly]="readonly"
               [required]="required"
               (focus)="inputFocus.emit()"
               (blur)="inputBlurHandler()"
               (input)="fieldInput.emit()">
      </ng-container>
    </div>

    <mat-hint>{{hint}}</mat-hint>
    <mat-error>{{formControl.errors | formControlError:{label: label, dataType: dataType} }}</mat-error>
  </mat-form-field>
</ng-template>
