<div *ngIf="rerender$ | async">
  <div>
    <ng-container *rxLet="activeProductIndex$; let activeProductIndex">
      <h2 class="section__title">{{form.get('productName').value || 'Product ' + (activeProductIndex + 1)}}</h2>
    </ng-container>
    <div (click)="removeProduct()" class="fpp-claim-product__remove-button">
      <mat-icon>close</mat-icon>
      <span>Remove this product from the claim</span>
    </div>
  </div>
  <div>
    <app-consumer-product-form *ngIf="!form.get('id').value" [form]="form"></app-consumer-product-form>
    <div>
      <h3 class="section__title">Describe the Problem</h3>
      <app-consumer-product-incident-form [planDeliveryDate]="planDeliveryDate$ | push"
                                          [incidentFormControl]="form.get('productIncident')"></app-consumer-product-incident-form>
    </div>
  </div>
  <div class="form-step-buttons">
    <button type="button"
            mat-flat-button
            color="primary"
            (click)="addAnotherProduct()"
            [disabled]="form.invalid">Add another product</button>
    <button type="button"
            id="button-back"
            (click)="goBack()"
            mat-flat-button color="primary">Back</button>
    <button type="button"
            id="button-next"
            [disabled]="form.invalid"
            (click)="goNext()"
            mat-flat-button color="primary">Next</button>
  </div>
</div>
