import { ChangeDetectionStrategy, Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { PushPipe } from '@rx-angular/template/push';

import { BaseFormControlElement } from '@core/base/base-form-control-element';
import { Identity } from '@core/interfaces/util/identity.interface';
import { LookupService } from '@core/services/lookup.service';

import {
  FormSelectNewComponent,
} from '../../../../../../th-consumer/app-mobile/shared/components/form-controls/form-select-new/form-select-new.component';

@Component({
  selector: 'app-surface-type-select-new',
  standalone: true,
  templateUrl: './surface-type-select-new.component.html',
  styleUrls: ['./surface-type-select-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormSelectNewComponent,
    PushPipe,
    ReactiveFormsModule,
  ],
})
export class SurfaceTypeSelectNewComponent extends BaseFormControlElement {
  @Input() readonly: boolean;
  @Input() required: boolean;

  @Output() selectedItem = new EventEmitter<Identity>();

  itemsList$ = this.lookupService.getLookup('surfacetype');

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly lookupService: LookupService) {
    super(ngControl);
  }

  itemSelected(surface: Identity): void {
    this.selectedItem.emit(surface);
  }
}
