import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';
import { zip } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ProductApiService } from '@core/api/product-api.service';
import { Product } from '@core/interfaces/quick-search/search-results.interface';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';

import { RegisterFppClaimStore } from '../../../../../../app/pages/register-fpp-claim/store/register-fpp-claim.store';
import {
  FormSelectNewComponent,
} from '../../../../../shared/components/form-controls/form-select-new/form-select-new.component';
import {
  BaseWizardPageComponent,
} from '../../../register-plan-consumer-mobile/components/base-wizard-page/base-wizard-page.component';
import {
  SelectProductBottomPopupComponent,
} from '../../dialogs/select-product-bottom-popup/select-product-bottom-popup.component';

interface ISelectProductsState {
  products: Product[];
  loadProductsInProgress: boolean;
}

@Component({
  selector: 'app-fpp-claim-select-product',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    FormSelectNewComponent,
    PushPipe,
    FormsModule,
    LetDirective,
    PortalModule,
    SelectProductBottomPopupComponent,
    LoadingButtonDirective,
  ],
  templateUrl: './fpp-claim-select-product.component.html',
  styleUrls: ['./fpp-claim-select-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    RxState,
  ],
})
export class FppClaimSelectProductComponent extends BaseWizardPageComponent implements OnInit {
  vm$ = this.state.select();
  selectedProductIds: string[] = [];

  constructor(
    private readonly store: RegisterFppClaimStore,
    private readonly state: RxState<ISelectProductsState>,
    private readonly productApiService: ProductApiService,
  ) {
    super();
    this.state.connect('products', this.store.select$('planDetails', 'coveredProducts'));
  }

  ngOnInit(): void {
    const products = this.store.getForm('coveredProducts').value;
    if (products.length > 0) {
      this.store.goToProduct(0);
    }
  }

  addNewProduct(): void {
    this.store.addProducts(null);
    this.store.goToProduct(0);
  }

  addSelectedProducts(): void {
    this.state.set({
      loadProductsInProgress: true,
    });
    zip(this.selectedProductIds.map(productId => this.productApiService.getByGuid(productId)))
      .pipe(
        finalize(() => {
          this.state.set({
            loadProductsInProgress: false,
          });
        }),
      )
      .subscribe(products => {
        this.store.addProducts(products);
        this.store.goToProduct(0);
      });
  }
}
