import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, Optional, Self } from '@angular/core';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldAppearance,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { BaseFormControlElement } from '@core/base/base-form-control-element';

import { FormControlErrorPipe } from '../../../form-control-error.pipe';

@Component({
  selector: 'app-form-textarea-new',
  standalone: true,
  templateUrl: './form-textarea-new.component.html',
  styleUrls: ['./form-textarea-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    MatInputModule,
    NgIf,
    MatIconModule,
    NgxTrimDirectiveModule,
    ReactiveFormsModule,
    FormControlErrorPipe,
  ],
})
export class FormTextareaNewComponent extends BaseFormControlElement {
  @Input() appearance: MatFormFieldAppearance = this.matFormFieldOptions.appearance;

  @Input() required: boolean;

  @Input() label = 'Tell us more.';

  @Input() errorMessage = '';

  @Input() showIsRequiredMessage = true;

  @Input() placeholder: string;

  @Input() hint: string;

  @Input() matTextareaAutosize = true;

  @Input() maxLength = 32769;

  @Input() minRows = 1;

  @Input() maxRows = 8;

  @Input() tooltipText = '';

  @Input() useTrim: '' | 'blur' | false = 'blur';

  tooltipAttentionText = '';

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) private readonly matFormFieldOptions: MatFormFieldDefaultOptions,
  ) {
    super(ngControl);
  }
}
