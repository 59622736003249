import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { PushPipe } from '@rx-angular/template/push';
import { map } from 'rxjs/operators';

import { BaseFormControlElement } from '@core/base/base-form-control-element';
import { Identity } from '@core/interfaces/util/identity.interface';
import { LookupService } from '@core/services/lookup.service';
import { PipesModule } from '@shared/pipes/pipes.module';

import {
  FormSelectNewComponent,
} from '../../../../../../th-consumer/app-mobile/shared/components/form-controls/form-select-new/form-select-new.component';

interface IContactMethodSelectState {
  selectedItem: Identity;
}

@Component({
  selector: 'app-contact-method-select-new',
  standalone: true,
  templateUrl: './contact-method-select.component.html',
  styleUrls: ['./contact-method-select.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormSelectNewComponent,
    PushPipe,
    PipesModule,
    NgIf,
    ReactiveFormsModule,
  ],
})
export class ContactMethodSelectComponent extends BaseFormControlElement implements OnInit {
  @Input() readonly = false;

  itemsList$ = this.lookupService.getLookup('contactmethod');
  selectedItemName$ = this.state.select('selectedItem', 'name');

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly lookupService: LookupService,
    private readonly state: RxState<IContactMethodSelectState>,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.state.connect(
      'selectedItem',
      this.itemsList$.pipe(map(items => items.find(item => item.id === this.formControl.value))),
    );
  }
}
