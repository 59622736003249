import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { parseZone } from 'moment/moment';

import { TDateFieldType } from '@core/enums/date/date-field-type.enum';
import { Plan } from '@core/interfaces/plan/plan.interface';

@Component({
  selector: 'app-consumer-product-incident-form',
  templateUrl: './consumer-product-incident-form.component.html',
  styleUrls: ['./consumer-product-incident-form.component.scss'],
})
export class ConsumerProductIncidentFormComponent implements OnInit {
  dateNoticedMinDate: Date;
  TDateFieldType = TDateFieldType;
  today = new Date();

  @Input() incidentFormControl: UntypedFormGroup;
  @Input() set planInfo(planInfo: Plan) {
    if (planInfo) {
      this.dateNoticedMinDate = parseZone(planInfo.deliveryDate).toDate();
    }
  }
  @Input() set planDeliveryDate(planDeliveryDate: string) {
    this.dateNoticedMinDate = parseZone(planDeliveryDate).toDate();
  }

  constructor() {}

  ngOnInit() {}
}
