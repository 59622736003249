import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, Optional,
  Output, Self, ViewChild,
} from '@angular/core';
import {
  FormsModule, NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { FloatLabelType, MatFormField } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PushPipe } from '@rx-angular/template/push';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { BaseFormControlElement } from '@core/base/base-form-control-element';
import { PasswordMatchesComponent } from '@shared/components/password-matches/password-matches.component';
import { CurrencyDirective } from '@shared/directives/currency/currency.directive';
import { OnlyNumberDirective } from '@shared/directives/only-number/only-number.directive';
import { PipesModule } from '@shared/pipes/pipes.module';

import { FormControlErrorPipe } from '../../../form-control-error.pipe';
import { LabelValueComponent } from '../../label-value/label-value.component';

type TReadOnlyType = 'input' | boolean;
type TInputType = 'password' | 'text' | 'tel' | 'email';
type TInputDataType = 'price' | 'only-number' | 'phone';

@Component({
  selector: 'app-form-input-new',
  standalone: true,
  templateUrl: './form-input-new.component.html',
  styleUrls: ['./form-input-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    NgIf,
    PipesModule,
    MatInputModule,
    MatIconModule,
    NgSwitch,
    NgxTrimDirectiveModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyDirective,
    NgSwitchCase,
    OnlyNumberDirective,
    NgSwitchDefault,
    PasswordMatchesComponent,
    NgTemplateOutlet,
    PushPipe,
    MatTooltipModule,
    LabelValueComponent,
    FormControlErrorPipe,
  ],
})
export class FormInputNewComponent extends BaseFormControlElement {
  @Input() floatLabel: FloatLabelType = 'auto';

  @Input() label: string;

  @Input() placeholder: string;

  @Input() hint: string;

  @Input() maxLength: number;

  @Input() minLength: number;

  @Input() readonly: TReadOnlyType = false;

  @Input() required: boolean;

  @Input() mask: Array<string | RegExp> | ((raw: string) => Array<string | RegExp>) | false = false;

  @Input() icon: string;

  @Input() type: TInputType = 'text';

  @Input() dataType: TInputDataType = null;

  @Input() autocomplete = null;

  @Input() big = false;

  @Input() currencyInput = false;

  @Input() numericInput = false;

  @Input() tooltipText = '';

  @Output() inputFocus = new EventEmitter<void>();

  @Output() inputBlur = new EventEmitter<void>();

  @Output() fieldInput = new EventEmitter<void>();

  tooltipAttentionText = '';

  @ViewChild(MatFormField) matFormField: MatFormField;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    super(ngControl);
  }

  enterClicked(): void {
    if (['price', 'only-number'].includes(this.dataType)) {
      return;
    }
    const searchString = this.formControl.value
      ? this.formControl.value.trim()
      : this.formControl.value;
    this.formControl.patchValue(searchString);
  }

  inputBlurHandler(): void {
    this.inputBlur.emit();
  }
}
