import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';
import { zip } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ProductApiService } from '@core/api/product-api.service';
import { Product } from '@core/interfaces/quick-search/search-results.interface';
import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';

import {
  BottomPopupDialogAnimationComponent,
} from '../../../../../shared/components/bottom-popup-dialog-animation/bottom-popup-dialog-animation.component';
import {
  FormSelectNewComponent,
} from '../../../../../shared/components/form-controls/form-select-new/form-select-new.component';

interface ISelectProductsState {
  products: Product[];
  loadProductsInProgress: boolean;
}

@Component({
  selector: 'app-select-product-bottom-popup',
  standalone: true,
  imports: [
    CommonModule,
    BottomPopupDialogAnimationComponent,
    PushPipe,
    FormSelectNewComponent,
    FormsModule,
    LetDirective,
    MatButtonModule,
    LoadingButtonDirective,
    MatIconModule,
  ],
  templateUrl: './select-product-bottom-popup.component.html',
  styleUrls: ['./select-product-bottom-popup.component.scss'],
  providers: [
    RxState,
    SideDialogStateService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectProductBottomPopupComponent {
  vm$ = this.state.select();
  dialogData = inject(MAT_DIALOG_DATA);
  selectedProductIds: string[] = [];

  constructor(
    private readonly state: RxState<ISelectProductsState>,
    private readonly sideDialogStateService: SideDialogStateService,
    private readonly productApiService: ProductApiService,
  ) {
    this.state.set({
      products: this.dialogData.data.products,
      loadProductsInProgress: false,
    });
  }

  addSelectedProducts(): void {
    this.state.set({
      loadProductsInProgress: true,
    });
    zip(this.selectedProductIds.map(productId => this.productApiService.getByGuid(productId)))
      .pipe(
        finalize(() => {
          this.state.set({
            loadProductsInProgress: false,
          });
        }),
      )
      .subscribe(products => {
        this.sideDialogStateService.close({
          selectedProducts: products,
        });
      });
  }

  addNewProduct(): void {
    this.sideDialogStateService.close({
      addNewProduct: true,
    });
  }

  close(): void {
    this.sideDialogStateService.close();
  }
}
