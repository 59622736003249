import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { ForModule } from '@rx-angular/template/for';
import { LetDirective } from '@rx-angular/template/let';
import { MyContactApiService } from '@th-common/app/core/api/my-contact-api.service';
import { ClaimWizard } from '@th-common/app/core/classes/claim-wizard';
import { ALLOWED_STATUSES } from '@th-common/app/core/constants/app.constants';
import { TDocumentType } from '@th-common/app/core/enums/document-type';
import { Address } from '@th-common/app/core/interfaces/address.interface';
import { Files } from '@th-common/app/core/interfaces/claims/files.interface';
import { ProductInterface } from '@th-common/app/core/interfaces/claims/product.interface';
import { IConsumer } from '@th-common/app/core/interfaces/consumer/consumer.interface';
import { Product } from '@th-common/app/core/interfaces/quick-search/search-results.interface';
import { ClaimFormService } from '@th-common/app/core/services/claim-form.service';
import { DialogService } from '@th-common/app/core/services/dialog.service';
import { UserStore } from '@th-common/app/core/store/user/user.store';
import moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { Feature } from '@core/enums/feature.enum';
import { PurchaseDetails } from '@core/interfaces/purchase/purchase.interface';
import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';
import { SwitchComponent } from '@shared/components/switch/switch.component';
import { ScrollableContentDirective } from '@shared/directives/scrollable-shell/scrollable-content.directive';
import { ClaimDialogsCoreModule } from '@shared/modules/claim-dialogs/claim-dialogs-core.module';
import {
  AddressSuggestComponent,
} from '@shared/modules/claim-dialogs/components/address-suggest/address-suggest.component';
import {
  CONST_existedDefaultConfig,
  CONST_fromScratchDefaultConfig,
} from '@shared/modules/claim-dialogs/components/select-product/select-product.component';
import { SelectProductDialogComponent } from '@shared/modules/claim-dialogs/components/select-product-dialog/select-product-dialog.component';
import { AddressType } from '@shared/modules/claim-dialogs/enums/address-type.enum';
import { ClaimFormSteps } from '@shared/modules/claim-dialogs/enums/claim-form-steps.enum';
import { ClaimFormModule } from '@shared/modules/claim-form/claim-form.module';
import { DialogCoreModule } from '@shared/modules/dialog-core/dialog-core.module';
import { FilesPreviewNewComponent } from '@shared/modules/files/components/files-preview-new/files-preview-new.component';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { NavigationModule } from '@shared/modules/navigation/navigation.module';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';
import {
  SideDialogLoaderComponent,
} from '@shared/modules/side-dialog/components/side-dialog-loader/side-dialog-loader.component';
import { WizardModule } from '@shared/modules/wizard/wizard.module';
import { AddressTemplateFormComponent } from '@shared/standalone/claim-forms/address-template-form/address-template-form.component';
import { ConsumerTemplateFormComponent } from '@shared/standalone/claim-forms/consumer-template-form/consumer-template-form.component';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';
import { FormFilesDropAreaComponent } from '@shared/standalone/file-controls/form-files-drop-area/form-files-drop-area.component';
import { FilesTotalSizeErrorComponent } from '@shared/standalone/files-total-size-error/files-total-size-error.component';
import { SideDialogAnimationComponent } from '@shared/standalone/side-dialog-animation/side-dialog-animation.component';

import { MyClaimApiService } from '../../core/api/my-claim-api.service';
import { MyPurchaseApiService } from '../../core/api/my-purchase-api.service';
import { IClaimProductFiles, IMyClaimFilesForm, validateClaimMaxFilesSize } from '../../core/utils/register-claim';

interface IRegisterClaimState {
  loading: boolean;
  hasFeatureAddNewProductsToClaim: boolean;
  showAddNewProduct: boolean;
  loadDetails: boolean;
}

@Component({
  selector: 'app-register-claim',
  templateUrl: './register-claim.component.html',
  styleUrls: ['./register-claim.component.scss'],
  providers: [RxState, SideDialogStateService],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    ClaimDialogsCoreModule,
    ClaimFormModule,

    DialogCoreModule,
    WizardModule,
    ConsumerTemplateFormComponent,
    AddressTemplateFormComponent,
    SideDialogAnimationComponent,
    ScrollableContentDirective,
    CustomOverlayScrollbarDirective,
    FormFilesDropAreaComponent,
    ForModule,
    FilesTotalSizeErrorComponent,
    SwitchComponent,
    MatButtonModule,
    MatIconModule,
    AddressSuggestComponent,
    MatIconModule,
    SideDialogLoaderComponent,
    FilesPreviewNewComponent,
    NgIf,
    LoaderModule,
    AsyncPipe,
    NgForOf,
    ReactiveFormsModule,
    FormsModule,
    NavigationModule,
    LetDirective,
  ],
})
export class RegisterClaimComponent extends ClaimWizard<any> implements OnInit, OnDestroy {
  dialogData = inject(MAT_DIALOG_DATA);
  hasFeatureAddNewProductsToClaim$ = this.state.select('hasFeatureAddNewProductsToClaim');
  showAddNewProduct$ = this.state.select('showAddNewProduct');
  loading$ = this.state.select('loading');
  loadDetails$ = this.state.select('loadDetails');
  plan: PurchaseDetails;
  steps = ClaimFormSteps;
  isPaperPlan = false;
  consumerKeysWithoutNameAndEmail = [
    'mobilePhone',
    'homePhone',
    'workPhone',
    'preferredContactLanguage',
    'preferredContactMethod',
    'preferredContactTime',
    'streetAddress1',
    'streetAddress2',
    'city',
    'stateProvince',
    'postalCode',
  ];

  planProducts: {products: Product[]} = {
    products: [],
  };
  isServiceAddressSame = false;
  photoSections: {title: string; required: boolean; description: string}[] = [
    {
      title: 'Photo 1',
      required: true,
      description:
        '<div class="register-claim__files-preview-requirement section">Show the entire damaged piece of furniture – '
        + 'stand far enough away to get the whole piece in the photo but close enough for it to fill the screen. '
        + 'Ideally, this photo should show the location of the damage.</div>',
    },
    {
      title: 'Photo 2',
      required: true,
      description:
        '<div class="register-claim__files-preview-requirement section">Focus on the area of furniture where the '
        + 'damage is located. For example, if it is a stain on a seat cushion, the photo should show the entire seat '
        + 'cushion. If it is a scratch on one leaf of a dining room table, it should show the entire leaf with the '
        + 'scratch on it.</div>',
    },
    {
      title: 'Photo 3',
      required: true,
      description:
        '<div class="register-claim__files-preview-requirement section">Take a close up of the damage itself. '
        + 'It should be close enough to show the detail of the damage but not so close that the image is out of focus. '
        + 'Please consider using a ruler or other object to provide scale.</div>',
    },
    {
      title: 'Photo 4',
      required: false,
      description:
        '<div class="register-claim__files-preview-requirement section">Provide a photo of the tags on '
        + 'the furniture showing the SKU number and the serial number.</div>\n'
        + '     <div class="register-claim__files-preview-requirement section"><strong><u>PLEASE READ ALL OF THIS BEFORE '
        + 'DECIDING IF IT IS SOMETHING YOU CAN COMFORTABLY DO.</u></strong></div>\n'
        + '     <div class="register-claim__files-preview-requirement section">The tags are often found on the underside or '
        + 'back of furniture. On upholstered furniture it is usually under the seat cushion(s) or under '
        + 'the footrest.</div>\n'
        + '     <div class="register-claim__files-preview-requirement section"><strong>WE DO NOT EXPECT YOU TO '
        + 'TRY TO MOVE OR TIP FURNITURE UNLESS YOU FEEL IT IS WITHIN YOUR PHYSICAL CAPABILITIES OR YOU HAVE '
        + 'SUITABLE HELP.</strong></div>\n'
        + '     <div class="register-claim__files-preview-requirement section">If you have concerns about supplying '
        + 'this, you can skip this photo and we will reach out at a later date to get the information we need.</div>\n',
    },
  ];
  feature = Feature;
  stopAddressSync$ = new Subject<void>();
  planNumber = '';

  filesForm = this.fb.group<IMyClaimFilesForm>(
    {
      productFiles: this.fb.array<FormControl<IClaimProductFiles>>([]),
    },
    {
      validators: [validateClaimMaxFilesSize()],
    },
  );

  productFiles$ = this.filesForm.get('productFiles').valueChanges;

  TDocumentType = TDocumentType;

  constructor(
    private readonly fb: FormBuilder,
    protected claimFormService: ClaimFormService,
    protected myPurchaseApiService: MyPurchaseApiService,
    protected myClaimApiService: MyClaimApiService,
    protected myContactApiService: MyContactApiService,
    protected matDialog: MatDialog,
    public notification: NotificationService,
    public cdr: ChangeDetectorRef,
    private readonly state: RxState<IRegisterClaimState>,
    protected readonly userStore: UserStore,
    protected readonly dialogService: DialogService,
    public readonly sideDialogStateService: SideDialogStateService,
  ) {
    super(claimFormService, matDialog, userStore);
    this.state.set({
      loadDetails: false,
      hasFeatureAddNewProductsToClaim: false,
    });
  }

  ngOnInit() {
    if (this.dialogData.data.purchaseId) {
      this.state.set({
        loadDetails: true,
      });
      this.myPurchaseApiService
        .detailed(this.dialogData.data.purchaseId)
        .pipe(
          map(purchase => {
            purchase.coveredProducts.forEach(product => {
              product.disabled = ALLOWED_STATUSES.indexOf(product.coveredProductStatus) === -1;
            });
            return purchase;
          }),
        )
        .subscribe(
          purchase => {
            this.state.set({ hasFeatureAddNewProductsToClaim: purchase.retailer.addNewProductsToClaim });
            this.initForm(purchase);
            this.state.set({
              loadDetails: false,
            });
          },
          () => {
            this.close();
            this.notification.next({
              message: 'An unexpected error has occurred.',
              type: NotificationType.Error,
            });
          },
        );
    } else if (this.dialogData.data.purchaseLookup) {
      const purchase: any = {
        planInfo: {
          id: this.dialogData.data.purchase.id,
          crmRefId: this.dialogData.data.purchase.crmConsumerPlanId,
          consumerPlanName: this.dialogData.data.purchase.consumerPlanName,
          deliveryDate: this.dialogData.data.purchase.deliveryDate,
        },
        consumer: {
          ...this.dialogData.data.purchaseLookup.consumer,
        },
        retailer: {},
        coveredProducts: this.dialogData.data.purchase.coveredProducts,
      };
      this.planNumber = this.dialogData.data.purchase.consumerPlanName;
      this.state.set({ hasFeatureAddNewProductsToClaim: this.dialogData.data.purchase.addNewProductsToClaim });
      purchase.coveredProducts.forEach(product => {
        product.disabled = ALLOWED_STATUSES.indexOf(product.coveredProductStatus) === -1;
      });
      this.initForm(purchase);
    } else if (this.dialogData.data.purchase) {
      const sessionData = this.userStore.getSession();
      const purchase: any = {
        planInfo: {
          id: this.dialogData.data.purchase.id,
          crmRefId: this.dialogData.data.purchase.crmConsumerPlanId,
          consumerPlanName: this.dialogData.data.purchase.consumerPlanName,
          deliveryDate: this.dialogData.data.purchase.deliveryDate,
        },
        consumer: {
          crmRefId: this.dialogData.data.purchase.crmConsumerId,
          contactId: this.dialogData.data.purchase.contactId,
          firstName: sessionData.userFirstName,
          lastName: sessionData.userLastName,
          emailAddress: sessionData.username,
        },
        retailer: {},
        coveredProducts: this.dialogData.data.purchase.coveredProducts,
      };
      this.planNumber = this.dialogData.data.purchase.consumerPlanName;
      this.state.set({ hasFeatureAddNewProductsToClaim: this.dialogData.data.purchase.addNewProductsToClaim });
      purchase.coveredProducts.forEach(product => {
        product.disabled = ALLOWED_STATUSES.indexOf(product.coveredProductStatus) === -1;
      });
      this.initForm(purchase);
    } else {
      super.ngOnInit();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.stopAddressSync$.next();
    this.stopAddressSync$.complete();
  }

  private initForm(plan: PurchaseDetails): void {
    this.plan = plan;

    const planServiceAddress: Address = {
      streetAddress1: plan.planInfo.serviceStreetAddress1,
      streetAddress2: plan.planInfo.serviceStreetAddress2,
      city: plan.planInfo.serviceCity,
      stateProvince: plan.planInfo.serviceState,
      postalCode: plan.planInfo.servicePostalCode,
    };
    this.form = this.fb.group({
      consumer: this.fb.group({
        info: this.claimFormService.getConsumerFields(plan.consumer, false, { defaultPreferences: false }),
        consumerAddress: this.claimFormService.getAddressFields(plan.consumer, AddressType.CustomerAddress),
        serviceAddress: this.claimFormService.getAddressFields(planServiceAddress, AddressType.ServiceAddress),
      }),
      coveredProducts: this.claimFormService.getCoveredProductsArray([]),
    });
    this.form.get('consumer.info.firstName').disable();
    this.form.get('consumer.info.lastName').disable();
    super.ngOnInit();
    this.isPaperPlan = plan.coveredProducts === null || plan.coveredProducts.length === 0;
    this.planProducts.products = this.plan.coveredProducts || [];
    this.setDefaultProducts();

    this.state.connect(
      'showAddNewProduct',
      this.form.get('coveredProducts').valueChanges.pipe(
        map(selectedCoveredProducts => selectedCoveredProducts.map(product => product.id)),
        map(selectedProductIds => {
          const allowAddNewProduct = this.state.get('hasFeatureAddNewProductsToClaim');
          const productsLeftIds = this.plan.coveredProducts.reduce((productIds, product) => {
            if (!product.disabled && !selectedProductIds.includes(product.id)) {
              productIds.push(product.id);
            }
            return productIds;
          }, []);
          return allowAddNewProduct || productsLeftIds.length > 0;
        }),
      ),
    );
  }

  private setDefaultProducts(): void {
    if (this.isPaperPlan) {
      this.addProduct(false);
      this.state.set({
        loading: false,
      });
    } else {
      this.state.set({
        loading: false,
      });
    }
  }

  initWizard(): void {
    this.navigationItems = {
      gettingStarted: {
        name: 'Getting Started',
        step: ClaimFormSteps.GettingStarted,
        nextStep: 'consumer',
        prevStep: null,
        formGroupName: null,
        readonly: false,
        extraValidation: (step: ClaimFormSteps) => step === ClaimFormSteps.GettingStarted,
      },
      consumer: {
        name: 'Consumer',
        step: ClaimFormSteps.Consumer,
        nextStep: 'coveredProducts',
        prevStep: 'gettingStarted',
        formGroupName: 'consumer',
        readonly: false,
      },
      coveredProducts: {
        name: 'Products on Claim',
        step: ClaimFormSteps.Products,
        nextStep: 'photos',
        prevStep: 'consumer',
        formGroupName: 'coveredProducts',
        readonly: false,
        child: [],
      },
      photos: {
        name: 'Images',
        step: ClaimFormSteps.PhotosAndDocuments,
        nextStep: null,
        prevStep: 'coveredProducts',
        formGroupName: null,
        readonly: false,
        extraValidation: (step: ClaimFormSteps, form: FormGroup) => !(step === ClaimFormSteps.PhotosAndDocuments && this.filesForm.value.productFiles.length === 0),
      },
    };

    this.navigationList = ['gettingStarted', 'consumer', 'coveredProducts', 'photos'];
    this.currentStep = this.navigationItems['gettingStarted'];
  }

  openAddProductDialog(): void {
    if (this.isPaperPlan) {
      this.addProduct();
    } else {
      const displayButtonAddNewProductsToClaim = this.state.get('hasFeatureAddNewProductsToClaim');
      const dialogRef = this.matDialog.open(SelectProductDialogComponent, {
        autoFocus: false,
        data: {
          products: this.planProducts.products,
          selectedProductIds: this.form.get('coveredProducts').value.map(product => product.id),
          config: {
            fromScratch: CONST_fromScratchDefaultConfig,
            existed: CONST_existedDefaultConfig,
            displayButtonAddNewProductsToClaim,
          },
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (!result) {
          return;
        }

        if (result.newProduct) {
          this.addProduct();
        } else {
          this.addProductsToForm(result.selectedProducts);
        }
      });
    }
  }

  submitForm(): void {
    if (this.submitInProgress) {
      return;
    }
    if (this.form.valid) {
      this.submitInProgress = true;
      // TODO: Try to move payload generation to service
      const claimValue: {consumer; consumerPlan; consumerAddress?; serviceAddress?} = {
        consumer: {
          id: null,
          crmRefId: this.plan.consumer.crmRefId,
          contactId: this.plan.consumer.contactId,
        },
        consumerPlan: {
          crmRefId: this.plan.planInfo.crmRefId,
          coveredProducts: this.form.value.coveredProducts,
        },
        serviceAddress: this.form.value.consumer.serviceAddress,
      };

      claimValue.consumerPlan.coveredProducts.forEach((product, index) => {
        product.productIncident.dateNoticed = moment(product.productIncident.dateNoticed).utc(true).format();
        product.clientRefObject = index.toString();
      });

      if (
        this.consumerWasChanged(
          {
            ...this.form.value.consumer.info,
            ...this.form.value.consumer.consumerAddress,
          },
          [...this.consumerKeysWithoutNameAndEmail, 'emailAddress'],
        )
      ) {
        this.updateContactInfoAndCreateClaim(
          {
            ...this.form.value.consumer.info,
            ...this.form.value.consumer.consumerAddress,
          },
          claimValue,
        );
      } else {
        this.createClaim(claimValue, this._flatFormFiles());
      }
    }
  }

  onClose(): void {
    if (this.submitInProgress) {
      return;
    }
    this.dialogService.canLeaveDefaultConfirmationObservable(this.form).subscribe(canLeave => {
      if (canLeave) {
        this.close();
      } else {
        return;
      }
    });
  }

  removeProduct(productIndex: number): void {
    (this.filesForm.get('productFiles') as FormArray).removeAt(productIndex);
    super.removeProduct(productIndex);
  }

  private updateContactInfoAndCreateClaim(consumer: IConsumer, claim: any) {
    return this.myContactApiService
      .contactInfo({
        ...consumer,
        planNumber: this.planNumber,
      })
      .subscribe(
        () => {
          this.createClaim(
            {
              ...claim,
              consumer: {
                id: null,
                crmRefId: this.plan.consumer.crmRefId,
                contactId: this.plan.consumer.contactId,
              },
            },
            this._flatFormFiles(),
          );
        },
        () => {
          this.submitInProgress = false;
        },
      );
  }

  private createClaim(claimValue: any, files: Files[]): void {
    claimValue.filesMetaData = files.map((file, index) => ({
      fileName: file.fileName || null,
      documentType: file.docType,
      clientReference: index.toString(),
    }));
    this.myClaimApiService.createClaim(claimValue, files).subscribe(
      () => {
        this.close();
      },
      () => {
        this.submitInProgress = false;
      },
    );
  }

  protected getProductFields(product?: ProductInterface, readonly: boolean = false) {
    return this.claimFormService.getConsumerProductFields(product, readonly);
  }

  protected getProductIncidentFields(values: any) {
    return this.claimFormService.getConsumerProductIncidentFields(values);
  }

  private consumerWasChanged(newConsumer: any, fields: string[]) {
    let consumerWasChanged = false;
    if (this.plan.consumer) {
      fields.forEach(key => {
        if (newConsumer.hasOwnProperty(key) && this.plan.consumer.hasOwnProperty(key)) {
          if (newConsumer[key] !== this.plan.consumer[key]) {
            consumerWasChanged = true;
          }
        }
      });
    } else {
      consumerWasChanged = true;
    }
    return consumerWasChanged;
  }

  coveredProductsAreInvalid(): boolean {
    if (this.form.get('coveredProducts')['controls'].length) {
      for (let i = 0; i <= this.currentSubStep; i++) {
        if (this.form.get('coveredProducts')['controls'][i].invalid) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  setInProgressState(value: boolean): void {
    this.nextButtonDisabled = value;
  }

  isServiceAddressSameChanged(): void {
    if (this.isServiceAddressSame) {
      this.form.get('consumer.serviceAddress').patchValue(this.form.get('consumer.consumerAddress').value);
      this.form
        .get('consumer.consumerAddress')
        .valueChanges.pipe(takeUntil(this.stopAddressSync$))
        .subscribe(address => {
          this.form.get('consumer.serviceAddress').patchValue(address);
        });
    } else {
      this.stopAddressSync$.next();
    }
  }

  close(): void {
    this.sideDialogStateService.close();
  }

  addProduct(goToStep: boolean = true): void {
    (this.form.get('coveredProducts') as FormArray).push(this.getProductFields({}));
    (this.filesForm.get('productFiles') as FormArray).push(
      this.fb.group({
        section1: this.fb.array([], Validators.required),
        section2: this.fb.array([], Validators.required),
        section3: this.fb.array([], Validators.required),
        section4: this.fb.array([]),
      }),
    );
    const productStepIndex = this.navigationItems.coveredProducts.child.push(this.returnCoveredProductChild()) - 1;
    if (goToStep) {
      this.goToStep('coveredProducts');
      this.currentSubStep = productStepIndex;
    }
  }

  addProductsToForm(products: Product[], goToStep: boolean = true): void {
    products.forEach((product, index) => {
      (this.form.get('coveredProducts') as FormArray).push(this.getProductFields(product, true));
      (this.filesForm.get('productFiles') as FormArray).push(
        this.fb.group({
          section1: this.fb.array([], Validators.required),
          section2: this.fb.array([], Validators.required),
          section3: this.fb.array([], Validators.required),
          section4: this.fb.array([]),
        }),
      );
      this.navigationItems.coveredProducts.child.push(this.returnCoveredProductChild(product));
      if (index === 0 && goToStep) {
        this.currentSubStep = this.navigationItems.coveredProducts.child.length - 1;
      }
    });
  }

  removeFileIndex(filesFormArray: FormArray, fileIndex: number): void {
    const updatedFiles = filesFormArray.value.filter(file => fileIndex !== file.index);
    filesFormArray.clear();
    updatedFiles.forEach(file => {
      filesFormArray.push(this.fb.control(file));
    });
  }

  private _flatFormFiles() {
    let flatFiles = [];
    this.filesForm.get('productFiles').value.forEach((product: any) => {
      flatFiles = flatFiles.concat(product.section1, product.section2, product.section3, product.section4);
    });

    return flatFiles;
  }
}
