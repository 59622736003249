<div *rxLet="vm$; let vm">
  <ng-container *formGroup="vm.productForm">
    <div class="fpp-claim-product__header">
      <p class="fpp-claim-product__title">{{vm.productName}}</p>
      <button type="button" mat-stroked-button class="link-button" (click)="deleteProduct()">Remove</button>
    </div>
    <ng-container *ngIf="!vm.productForm.value.id">
      <app-form-textarea-new formControlName="productDescription"
                             [required]="true"
                             [label]="'Describe the Product'"></app-form-textarea-new>
      <!--  [hint]="'What is the piece of furniture you are making a claim on? E.g. “fabric sofa”, “dining table”, “leather recliner with power”.'"  -->
      <app-surface-type-select-new [required]="true"
                                   formControlName="surfaceTypeId"
                                   (selectedItem)="selectedSurfaceType($event)"></app-surface-type-select-new>
    </ng-container>
    <div formGroupName="productIncident">
      <app-form-textarea-new formControlName="damageDescription"
                             [required]="true"
                             [label]="'Describe the Problem'"></app-form-textarea-new>
      <app-cause-select-new [label]="'How Did it Happen'"
                            formControlName="causeTypeId"
                            [required]="true"
                            (selectedItem)="selectedCauseType($event)"></app-cause-select-new>
      <app-problem-type-select-new [label]="'Reported Damage'"
                                   formControlName="problemTypeId"
                                   [required]="true"
                                   (problemTypeSelected)="selectedProblemType($event)"></app-problem-type-select-new>
      <app-form-date-new *appReadonlyControl="vm.productForm.get('productIncident.dateNoticed').enabled; else labelValueItem; label: 'Date Noticed'; value: vm.productForm.get('productIncident.dateNoticed').value; let label = label"
                         formControlName="dateNoticed"
                         [touchUi]="true"
                         [label]="label"
                         [autocomplete]="'section-purchase purchase-date'"
                         [required]="true"
                         [readonly]="true"
                         [max]="today"
                         [min]="dateNoticedMinDate"></app-form-date-new>
<!--      [tooltipText]="'When did you first notice the problem? (It can be helpful to establish the date by thinking about other events on the day you first noticed it, e.g. “it was the day we went to pick out new carpet”, “it was the day your friend from work came over for dinner”, “it was the night we watched that scary movie”.)'"-->
    </div>
  </ng-container>
</div>
<ng-container *cdkPortal>
  <div class="fpp-claim-product__footer" *rxLet="disableContinueBtn$; let disableContinueBtn">
    <button mat-flat-button
            [class._common-button_submit-disabled]="disableContinueBtn"
            (click)="nextProduct(disableContinueBtn)">Continue</button>
  </div>
</ng-container>
<ng-template #labelValueItem let-label="label" let-value="value">
  <app-label-value [label]="label">
    <p>{{value | dateCustomFormat:'MM/DD/YYYY' | appEmptyValue}}</p>
  </app-label-value>
</ng-template>
