<div class="fields-block">
  <app-form-textarea class="field field--long"
                     [formControl]="incidentFormControl.get('damageDescription')"
                     [required]="true"
                     [tooltipText]="'Describe the problem, e.g. “coffee stain on the arm of the sofa”, “I spilled nail polish remover on my dining table”, “the power mechanism stopped working on my recliner”.'"
                     [label]="'Describe the Problem'"></app-form-textarea>
</div>
<div class="fields-block">
  <app-cause-select class="field field--third"
                    [label]="'How Did it Happen'"
                    [formControl]="incidentFormControl.get('causeTypeId')"
                    [required]="true"></app-cause-select>
  <app-problem-type-select class="field field--third"
                           [label]="'Reported Damage'"
                           [formControl]="incidentFormControl.get('problemTypeId')"
                           [required]="true"></app-problem-type-select>
</div>
<div class="fields-block">
  <app-form-date class="field field--third"
                 [formControl]="incidentFormControl.get('dateNoticed')"
                 [label]="'Date Noticed'"
                 [autocomplete]="'section-purchase purchase-date'"
                 [tooltipText]="'When did you first notice the problem? (It can be helpful to establish the date by thinking about other events on the day you first noticed it, e.g. “it was the day we went to pick out new carpet”, “it was the day your friend from work came over for dinner”, “it was the night we watched that scary movie”.)'"
                 [required]="true"
                 [readonly]="true"
                 [max]="today"
                 [min]="dateNoticedMinDate"></app-form-date>
</div>
