import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { uniqWith } from 'lodash-es';

import { AppConstants } from '@core/constants/app.constants';
import { Files } from '@core/interfaces/claims/files.interface';

export interface IClaimProductFiles {
  section1: Files[];
  section2: Files[];
  section3: Files[];
  section4: Files[];
}

export interface IClaimFilesForm {
  receiptFiles: FormArray<FormControl<Files>>;
  productFiles: FormArray<FormControl<IClaimProductFiles>>;
}

export interface IMyClaimFilesForm {
  productFiles: FormArray<FormControl<IClaimProductFiles>>;
}

export function validateClaimMaxFilesSize(maxFilesSize: number = AppConstants.maxFilesSize) {
  return (formGroup: FormGroup<{
    receiptFiles: FormArray<FormControl<Files>>;
    productFiles: FormArray<FormControl<IClaimProductFiles>>;
  }>) => {
    const totalReceiptFilesSize = (formGroup.value.receiptFiles || []).reduce((filesSize, file) => {
      filesSize += file.size;
      return filesSize;
    }, 0);
    const totalProductFilesSize = formGroup.value.productFiles.map(product => product.section1.concat(product.section2, product.section3, product.section4)).flat().reduce((filesSize, file) => {
      filesSize += file.size;
      return filesSize;
    }, 0);
    const totalFilesSize = totalReceiptFilesSize + totalProductFilesSize;
    if (totalFilesSize > maxFilesSize) {
      return {
        sizeError: {
          maxFilesSize,
          totalFilesSize,
        },
      };
    } else {
      return null;
    }
  };
}

export function validateIdenticalFiles() {
  return (c: FormArray<FormControl<Files>>) => {
    const identicalFiles = uniqWith(c.value, (arrVal, othVal) => arrVal.fileName === othVal.fileName && arrVal.size === othVal.size);
    const errorMessage = '';
    if (identicalFiles.length < c.value.length) {
      return {
        identicalFiles: {
          errorMessage,
        },
      };
    } else {
      return null;
    }
  };
}
