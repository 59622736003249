import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { DestroyableComponent } from '@core/classes/destroyable-component';

@Component({
  selector: 'app-consumer-product-form',
  templateUrl: './consumer-product-form.component.html',
  styleUrls: ['./consumer-product-form.component.scss'],
})
export class ConsumerProductFormComponent extends DestroyableComponent {
  @Input() form: UntypedFormGroup;
  @Input() readonly = false;
  @Output() productNameChanged = new EventEmitter<string>();

  constructor() {
    super();
  }
}
