<div *ngIf="!readonly" [formGroup]="form">
  <div class="fields-block">
    <app-form-textarea class="field field--long"
                       [formControl]="form.get('productDescription')"
                       [required]="true"
                       [tooltipText]="'What is the piece of furniture you are making a claim on? E.g. “fabric sofa”, “dining table”, “leather recliner with power”.'"
                       [label]="'Describe the Product'"></app-form-textarea>
  </div>
  <div class="fields-block">
    <app-surface-type-select class="field field--third"
                             [required]="true"
                             [formControl]="form.get('surfaceTypeId')"></app-surface-type-select>
  </div>
</div>
