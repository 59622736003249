import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';
import { parseZone } from 'moment';
import { map, startWith, switchMap } from 'rxjs/operators';

import { Identity, IIdentityString } from '@core/interfaces/util/identity.interface';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { FormGroupDirective } from '@shared/directives/form-group.directive';
import { PipesModule } from '@shared/pipes/pipes.module';
import {
  CauseSelectNewComponent,
} from '@shared/standalone/claim-controls/product-incident/cause-select-new/cause-select-new.component';
import {
  ProblemTypeSelectNewComponent,
} from '@shared/standalone/claim-controls/product-incident/problem-type-select-new/problem-type-select-new.component';
import {
  SurfaceTypeSelectNewComponent,
} from '@shared/standalone/claim-controls/surface-type-select-new/surface-type-select-new.component';

import { ReadonlyControlDirective } from '../../../../../../../th-tech/app/shared/directives/readonly-control.directive';
import { RegisterFppClaimStore } from '../../../../../../app/pages/register-fpp-claim/store/register-fpp-claim.store';
import {
  FormDateNewComponent,
} from '../../../../../shared/components/form-controls/form-date-new/form-date-new.component';
import {
  FormTextareaNewComponent,
} from '../../../../../shared/components/form-controls/form-textarea-new/form-textarea-new.component';
import { LabelValueComponent } from '../../../../../shared/components/label-value/label-value.component';
import {
  BaseWizardPageComponent,
} from '../../../register-plan-consumer-mobile/components/base-wizard-page/base-wizard-page.component';
import {
  SelectProductBottomPopupComponent,
} from '../../dialogs/select-product-bottom-popup/select-product-bottom-popup.component';

interface ISelectProductsState {
  productForm: FormGroup;
  productName: string;
  activeProductIndex: number;
  disableContinueBtn: boolean;
  dateNoticedMinDate: string;
}

@Component({
  selector: 'app-fpp-claim-product-mobile',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    FormTextareaNewComponent,
    ReactiveFormsModule,
    SurfaceTypeSelectNewComponent,
    MatButtonModule,
    PortalModule,
    FormGroupDirective,
    CauseSelectNewComponent,
    ProblemTypeSelectNewComponent,
    PushPipe,
    FormDateNewComponent,
    SelectProductBottomPopupComponent,
    ReadonlyControlDirective,
    LabelValueComponent,
    PipesModule,
  ],
  templateUrl: './fpp-claim-product-mobile.component.html',
  styleUrls: ['./fpp-claim-product-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    RxState,
  ],
})
export class FppClaimProductMobileComponent extends BaseWizardPageComponent {
  vm$ = this.state.select();
  disableContinueBtn$ = this.state.select('disableContinueBtn');

  today = new Date();
  dateNoticedMinDate = parseZone(this.store.get('planDetails').planInfo.deliveryDate).toDate();

  constructor(
    private readonly state: RxState<ISelectProductsState>,
    private readonly store: RegisterFppClaimStore,
    private readonly modalDialogService: ModalDialogService,
  ) {
    super();
    this.state.connect(this.store.select$('activeProductIndex'), (oldState, activeProductIndex) =>  {
      const productForm = (this.store.getForm('coveredProducts') as FormArray).at(activeProductIndex) as FormGroup;
      return {
        ...oldState,
        productForm,
        productName: productForm.value.id ? productForm.getRawValue().productName : `Product ${activeProductIndex + 1}`,
        activeProductIndex,
      };
    });
    this.state.connect('disableContinueBtn', this.state.select('productForm').pipe(
      switchMap((productForm) => productForm.statusChanges.pipe(
        startWith(productForm.status),
        map((status) => status === 'INVALID'),
      )),
    ));
  }

  nextProduct(disableContinueBtn: boolean): void {
    if (disableContinueBtn) {
      const productForm = this.state.get('productForm');
      productForm.markAllAsTouched();
      return;
    }
    this.store.wizardNavigationStore.setStep('entireProductFile');
  }

  deleteProduct(): void {
    const activeProductIndex = this.store.get('activeProductIndex');
    this.store.removeProduct(activeProductIndex);

    const products = (this.store.getForm('coveredProducts') as FormArray).value;
    if (products.length === 0) {
      this.store.wizardNavigationStore.setStep('selectProducts');
    } else {
      if (activeProductIndex === 0) {
        this._resetProduct();
      } else {
        this.store.updateState({
          activeProductIndex: activeProductIndex - 1,
        });
        this.store.wizardNavigationStore.setStep('incidentReview');
      }
    }
  }

  selectedSurfaceType(surface: Identity): void {
    const productForm = this.state.get('productForm');
    productForm.patchValue({
      surfaceTypeName: surface.name,
    });
  }

  selectedCauseType(causeType: Identity): void {
    const incidentForm = this.state.get('productForm').get('productIncident') as FormGroup;
    incidentForm.patchValue({
      causeTypeName: causeType.name,
    });
  }

  selectedProblemType(problemType: IIdentityString): void {
    const incidentForm = this.state.get('productForm').get('productIncident') as FormGroup;
    incidentForm.patchValue({
      problemTypeName: problemType.name,
    });
  }

  private _resetProduct(): void {
    const activeProductIndex = this.store.get('activeProductIndex');
    const productForm = (this.store.getForm('coveredProducts') as FormArray).at(activeProductIndex) as FormGroup;
    this.state.set({
      productForm,
      productName: productForm.value.id ? productForm.getRawValue().productName : `Product ${activeProductIndex + 1}`,
    });
  }
}
