<mat-form-field class="form-textarea"
                [class._common-theme-field]="!!formControl.value"
                [color]="'primary'"
                [appearance]="appearance">
  <mat-label *ngIf="label">{{label}}</mat-label>
  <div [class.tooltip__input-block]="tooltipText || tooltipAttentionText">
    <textarea matInput
            [trim]="useTrim"
            [class._common-tooltipText]="tooltipText || tooltipAttentionText"
            [cdkTextareaAutosize]="matTextareaAutosize"
            [required]="required"
            [cdkAutosizeMinRows]="minRows"
            [cdkAutosizeMaxRows]="maxRows"
            [maxlength]="maxLength"
            [placeholder]="placeholder"
            [formControl]="formControl"></textarea>
    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
  </div>
  <ng-container matSuffix>
    <mat-icon class="control-error-icon">error</mat-icon>
  </ng-container>

  <mat-error>{{formControl.errors | formControlError:{label: label} }}</mat-error>
</mat-form-field>
